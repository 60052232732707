$white: #FFF;
$black: #000;
$lightGray: #d4d4d4;
$mediumGray: darken($lightGray, 20%);
$darkGray: darken($mediumGray, 20%);
$warning-border-color: rgba(255, 114, 114, 0.25);
$rowSelectionColor: lightgreen;
$appLinkColor: #61dafb;

$darkmode-rowSelectionColor: darkgoldenrod;
$darkMode-form-background: #222;
$darkMode-background-color: #444;
$darkmode-button-active-background-color: darkblue;
$darkmode-button-active-color: $mediumGray;

$font-size-small: 8pt;
$font-size-medium: 12pt;
$font-size-large: 20pt;
$fixedFooterHeight: 70px;

/* ----------------------------------------------
 * Generated by Animista on 2024-8-15 21:44:16
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
 @-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

// Key frame for the shake error
// You can tweak the transforms to get any effect you want.
@keyframes shakeError {
  0% {
    transform: translateX(0);
  }
  15% {
    transform: translateX(0.375rem);
  }
  30% {
    transform: translateX(-0.375rem);
  }
  45% {
    transform: translateX(0.375rem);
  }
  60% {
    transform: translateX(-0.375rem);
  }
  75% {
    transform: translateX(0.375rem);
  }
  90% {
    transform: translateX(-0.375rem);
  }
  100% {
    transform: translateX(0);
  }
}

body {
  overflow: hidden;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  position: fixed;
  bottom: $fixedFooterHeight;
  right: 0;
  left: 0;
  top: 0;
}

#main-view {
  -webkit-overflow-scrolling: touch;
  height: 100%;

  @media only screen and (min-device-height: 600px) {
    margin-top: 10%;
  }
  
  margin-bottom: 10%;
}

.heartbeat {
	-webkit-animation: heartbeat 1.5s ease-in-out infinite both;
  animation: heartbeat 1.5s ease-in-out infinite both;
}

.input--error {
  -webkit-animation: shakeError 0.5s ease-in-out;
  animation: shakeError 0.5s ease-in-out;
  animation-timing-function: ease-in-out;
}

.App {
  text-align: center;
}

.btn.hide-button {
  display: none;
}

nav.nav.nav-tabs.hide {
  display: none;
}


.progress-bar.progress-bar-global-stats {
  text-align: start;
  padding-left: 20px;
}

.show-hint-button-text {
  margin-left: 10px;
  @media only screen and (max-device-width: 576px) {
    display: none;
  }
}

.btn.btn-quiz-answer {
  padding: 15px 5px;
  margin: 2% 0;
  font-size: 14pt;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
  &.btn-quiz-answer-one-two-x {
    &[disabled] {
      opacity: 1;
    }
  }
  &[disabled] {
    cursor: default;
    opacity: .2;
  }
}

#row-header {
  padding: 10px 0 20px 0;
}

.darkmode {
  background-color: #444;
}

#footer {
  &.fixed-bottom {
    background-color: $lightGray;
    width: 100%;
  }
}

.btn-toolbar.button-toolbar-in-footer {
  justify-content: space-between;
  &.in-introduction {
    justify-content: center;  
  }
}
